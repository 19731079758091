<template>
	<Row :gutter="10">
		<Col span="2" >
		<Affix offset-top="100" >
			<List header="分类菜单" border size="small" style="margin: 10px;" v-show="category.length != 0">
				<ListItem v-for="(pintai,name) in category" :key="name"><a @click.prevent="scrollTo(name)">{{name}}</a></ListItem>
			</List>
		</Affix>
		</Col>
		<Col span="20">
		<Row :gutter="16" v-for="(pintai,name) in category" :key="name">
			<Divider orientation="left" style="font-weight: 600;" :id="name">{{name}} 工具团购</Divider>
			<Col span="4" v-for="item in pintai" :key="item.id" style="margin-bottom: 16px;">
			<Card style="width:100%">
				<template #title>
					<Space>
						<Avatar :src="item.thumb" size="large" shape="square"></Avatar>
						<Title :level="5" style="text-transform: capitalize;height: 18px;"
							v-if="item.name.split('.').length > 1">{{nameIndx(item.name,0)}}&nbsp;<span
								style="color:#2b85e4">{{nameIndx(item.name,1)}}</span></Title>
						<Title :level="5" style="text-transform: capitalize;height: 18px;" v-else>
							{{nameIndx(item.name,0)}}
						</Title>
						<Tag style="position: absolute;top:-15px;left: 0px;" color="warning" v-if="item.type =='fanli'">官方授权</Tag>
					</Space>
				</template>
				<Row :gutter="16">
					<Col span="24">
					<Ellipsis :text="item.description" :lines="5" tooltip
						style="font-size: 12px;line-height: 2em;margin-top: 5px;" />
					</Col>
				</Row>
				<br />
				<Row v-if="item.type == 'gongxiang'">
					<Title :level="5" style="text-align: center;width: 100%">价格：{{item.price}} 元/月</Title>
					<PlanSelectVue color="primary" text="订阅" :platform="item.name"></PlanSelectVue>
				</Row>
				<Row v-else>
					<Title :level="5" style="text-align: center;width: 100%">价格：{{item.price}} 元/月 <Text type="danger" v-if="item.off > 0">优惠{{item.off}}元</Text></Title>
					<KefuVue :url="item.url" :money="item.money"></KefuVue>
				</Row>
			</Card>
			</Col>
		</Row>
		</Col>
	</Row>

</template>

<script>
	import PlanSelectVue from './PlanSelect.vue'
	import KefuVue from './KefuModel.vue'
	export default {
		data() {
			return {
				type: 'price',
				unit: {
					price: '月',
					quaprice: '季',
					yearprice: '年'
				},
				category: []
			}
		},
		created() {
			this.$axios.get('/index/pintailist').then((resp) => {
				this.category = resp.data
			})
		},
		components: {
			PlanSelectVue,
			KefuVue
		},
		methods: {
			add_to_cars(item) {
				if (this.IsLogin != true) {
					this.$Message.error({
						content: "请先登录，再进行操作"
					});
				} else {
					let key = `car-platform-${this.type}-${item.id}`;
					if (key in this.$store.state.cars) {
						this.$Message.error({
							content: "该平台已经在购物车了"
						});
					} else {
						let unitarr = {
							price: "MONTH",
							quaprice: "QUARTER",
							yearprice: "YEAR"
						}
						this.$store.commit('AddCars', {
							'key': key,
							'product': {
								unit: unitarr[this.type],
								price: item[this.type],
								thumb: item.thumb,
								platform: item.name,
								per: item.perlimit
							}
						})
						this.$Message.success({
							content: "添加成功"
						});
					}
				}
			},
			nameIndx(name, index) {
				return name.split('.')[index];
			},
			scrollTo(id) {
				document.getElementById(id)?.scrollIntoView({
					behavior: 'smooth'
				});
			}
		},
		computed: {
			IsLogin() {
				if (this.$store.state.jwt != '') {
					return true;
				} else {
					return false;
				}
			}
		}
	}
</script>

<style>
	.product_item {
		background-color: rgb(239, 245, 251);
		margin-bottom: 10px;
		overflow: hidden;
		padding: 10px;
	}

	.product_item .thumb {
		float: left;
	}

	.product_item .info {
		padding: 10px;
		float: left;
		position: relative;
	}

	.product_item .type {
		position: absolute;
		left: 10px;
		top: 8px
	}
</style>