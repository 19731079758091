<template>
	<ButtonGroup style="width: 100%;">
		<Button style="width: 50%;" :to="url" type="primary">官方注册</Button>
		<Button style="width: 50%;" @click="modal = !modal" type="primary">
			返利{{money}}元
		</Button>
	</ButtonGroup>
	<Modal v-model="modal"  title="联系客服" :width="250">
		
		<p class="ivu-text-center"><img :src="require('@/assets/wx.8ec6ad77.png')" alt="waimao518666" width="150"></p>
		<Title class="ivu-text-center" :level="5">支付成功后，截图联系微信客服 waimao518666 获取返利</Title>
	</Modal>
</template>

<script>
	export default {
		props:{
			money: String,
			url: String
		},
		data() {
			return {
				modal: false
			}
		},
	}
</script>

<style>
</style>