<template>
	<Row class="hero ivu-text-center" id="hero">
		<Col span="24">
		<Title :level="1" style="color: #fff;"><span style="color: #2b85e4;">外贸518</span>工具团购</Title>
		<Title :level="4" style="color: #fff;">稳定，方便 的外贸工具团购,助力外贸中小型卖家成长！</Title>
		</Col>
	</Row>
	<br>
	<ProductList></ProductList>
</template>

<script>
	import ProductList from '@/components/ProductList.vue'
	export default {
		name: 'HomeView',
		components: {
			ProductList
		},
	}
</script>

<style>
	.hero {
		background-color: rgb(239, 245, 251);
		background-image: url('/src/assets/bg22.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding: 50px;
		color: #ffffff;
	}
</style>